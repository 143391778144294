import CampaignService from "@/scripts/services/campaign.service";
import { CampaignTypes } from "@/scripts/models/enums/campaignTypes.enum";

const CampaignModule = {
    state: {
        landingPageConfig: null,
        service: new CampaignService(),
        campaignTypes: CampaignTypes,
        isIncetived: false,
        incomeTax: "6%",
    },
    mutations: {
        INIT_CAMPAIGN_LANDINGPAGE_CONFIG: (state, payload) => {
            state.landingPageConfig = payload
        },
        SET_PROJECT_INCETIVED: (state, payload) => {
            state.isIncetived = payload
        },
        SET_INCOME_TAX: (state) => {
            if (state.landingPageConfig && state.landingPageConfig.campaign && state.landingPageConfig.campaign.simulatorPercentage)
                state.incomeTax = state.landingPageConfig.campaign.simulatorPercentage + '%';
            else
                state.incomeTax = '0%'; 
        }
    },
    getters: {
        campaignLandingPageConfig: state => {
            return state.landingPageConfig;
        },
        isIncetived: state => {
            return state.isIncetived;
        },
        incomeTax: state => {
            return state.incomeTax;
        }
    },
    actions: {
        setLandingPageConfig: async state => {
            try {
                if(state.state.landingPageConfig == null){
                    const payload = await state.state.service.getCampaignLandingPageConfigBySubdomain(false)
                    state.commit("INIT_CAMPAIGN_LANDINGPAGE_CONFIG", payload)
                }
            } catch (error) {
                throw new Error(`Erro ao carregar dados da campaign landing page ${error}`);
            }
            
        },
        checkCampaignType: async state => {
            try {
                const campaign = await state.state.service.findBySubdomain()
                const isIncetived = campaign.campaginTypeId === state.state.campaignTypes.Incentivada   
                state.commit('SET_PROJECT_INCETIVED', isIncetived)
            } catch (error) {
                throw new Error(`Erro ao carregar dados do carrinho ${error}`);
            }
        },
        setIncomeTax: async state => {            
            if (state.LandingPageConfig)
                state.commit('SET_INCOME_TAX')
            else {
                await state.dispatch('setLandingPageConfig')
                state.commit('SET_INCOME_TAX')
            }
        }
    }
}

export default CampaignModule