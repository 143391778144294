
import BaseService from "./base.service";
import PagSeguroService from "./pagSeguro.service";
import PagarMeService from "./pagarMe.service";
import VindiService from "./vindi.service";
import CommonHelper from '../helpers/common.helper';
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";
import CreditCard from "@/scripts/models/creditCard.model";

export default class CartService extends BaseService {

	constructor(pagSeguroDirectPayment, pagarMe) {
		super('Cart');
		this.pagSeguroService = new PagSeguroService(pagSeguroDirectPayment);
		this.pagarMeService = new PagarMeService(pagarMe);
		this.vindiService = new VindiService();
	}

	getCart() {
		return this.getRequest('getCart');
	}

	getCartById(cartId) {
		cartId = parseInt(cartId);
		return this.getRequest('GetCartById', { cartId: cartId });
	}

	doesCartHaveItems() {
		return this.getRequest('DoesCartHaveItems');
	}

	addCartItem(projectId, donationProductId, showMessage = true, showLoader = true) {
		projectId = parseInt(projectId);
		if (donationProductId) donationProductId = parseInt(donationProductId);
		return this.postRequest('addCartItem', { projectId: projectId, donationProductId: donationProductId }, showMessage, showLoader);
	}

	mergeCartItems(cartItems) {
		return this.postRequest('MergeCartItems', cartItems);
	}

	removeCartItem(cartItemId, showMessage = true, showLoader = true) {
		cartItemId = parseInt(cartItemId);
		return this.deleteRequest('removeCartItem', { cartItemId: cartItemId }, showMessage, showLoader);
	}

	removeCartItemByProjectAndDonationProduct(projectId, donationProductId, showMessage = true, showLoader = true) {
		return this.postRequest('removeCartItemByProjectAndDonationProduct', { projectId: projectId, donationProductId: donationProductId }, showMessage, showLoader);
	}

	checkout(cart) {
		return this.putRequest('checkout', cart);
	}

	ticketCheckout(cart) {
		const cartToBeCheckout = this.cloneCartAndCleanData(cart);
		return this.postRequest('ticketCheckout', cartToBeCheckout);
	}

	getLoggedUserClosedCarts(begin, end, qtyOfCarts = null) {
		let show = true;

		return this.getRequest('GetLoggedUserClosedCarts', { begin, end, qtyOfCarts }, true, show);
	}

	cancelPayment(cart) {
		return this.postRequest('CancelPayment', {id: cart.id});
	}

	generateTicketAgain(cart) {
		return this.postRequest('GenerateTicketAgain', cart);
	}

	changeCartStatusToApproved(ticket) {
		return this.postRequest('ChangeCartStatusToApproved', ticket);
	}

	changeCartStatusToWaitingForApproval(ticket) {
		return this.postRequest('ChangeCartStatusToWaitingForApproval', ticket);
	}

	changeCartStatusToExpired(ticket) {
		return this.postRequest('ChangeCartStatusToExpired', ticket);
	}

	listTicketDetailsByCartStatus(cartStatus) {
		return this.getRequest('ListTicketDetailsByCartStatus', { cartStatus: cartStatus });
	}

	cloneCartAndCleanData(cart) {
		let copy = {...cart}
		copy.cartItems.forEach(ci => {
			if(ci.project) ci.projectId = ci.project.id;
			ci.project = null;
		});
		copy.campaign = null;
		return copy;
	}

	creditCardCheckout(cart) {
		let cartToBeCheckedOut = this.cloneCartAndCleanData(cart);

		CommonHelper.showLoader();

		const creditCard = new CreditCard();
		creditCard.cardNumber = cart.checkoutRequest.creditCardInfo.cardNumber;
		creditCard.cardName = cart.checkoutRequest.creditCardInfo.holder.name;
		creditCard.expirationDate = cart.checkoutRequest.creditCardInfo.expirationMonth.toString() +  cart.checkoutRequest.creditCardInfo.expirationYear.toString();
		creditCard.CVV = cart.checkoutRequest.creditCardInfo.cvv;
		creditCard.saveCreditCard = cart.checkoutRequest.creditCardInfo.save

		if (cartToBeCheckedOut.paymentMethodProviderId === PaymentMethodProvider.PagSeguro) {
			return this.pagSeguroService.prepareCreditCardCheckout(cartToBeCheckedOut.checkoutRequest)
				.then(function (checkoutRequestFulfilled) {
					cartToBeCheckedOut.checkoutRequest = checkoutRequestFulfilled;

					return this.checkout(cartToBeCheckedOut);
				}.bind(this))
				.finally(() => {
					CommonHelper.hideLoader();
				});
		}
		else if (cartToBeCheckedOut.paymentMethodProviderId === PaymentMethodProvider.PagarMe) {
			if(cart.checkoutRequest.creditCardInfo.creditCardId != ""){
				return this.checkout(cartToBeCheckedOut).finally(() => {
					CommonHelper.hideLoader();
				});
			}

			return this.createCreditCardOnGateway(creditCard).then(function (response) {
				const creditCardId = response
				cartToBeCheckedOut.checkoutRequest.creditCardInfo.creditCardId = creditCardId
				cartToBeCheckedOut.checkoutRequest.creditCardInfo.id = creditCardId
				return this.checkout(cartToBeCheckedOut);
			}.bind(this)).finally(() => {
				CommonHelper.hideLoader();
			});
		}
		else if (cartToBeCheckedOut.paymentMethodProviderId === PaymentMethodProvider.Asaas) {
			if(cart.checkoutRequest.creditCardInfo.creditCardId != ""){
				return this.checkout(cartToBeCheckedOut).finally(() => {
					CommonHelper.hideLoader();
				});
			}
			cartToBeCheckedOut.checkoutRequest.creditCardInfo.creditCard = creditCard;
			return this.checkout(cartToBeCheckedOut).finally(() => {
				CommonHelper.hideLoader();
			})
		}
	}

	async pagSeguroCreditCardPreCheckout(cartToBeCheckedOut) {
		
	}

	debitAccountCheckout(cart) {
		let cartToBeCheckedOut = this.cloneCartAndCleanData(cart);
		// return this.vindiService.createPaymentProfile(cartToBeCheckedOut.checkoutRequest).then(function (response) {
		// 	console.log(response.data);
		// 	return this.checkout(cartToBeCheckedOut);
		// }.bind(this)).catch(function (error) {
		// 	console.log(error);
		// });
		return this.checkout(cartToBeCheckedOut);
	}

	retryDonation(cartId) {
		return this.getRequest('RetryDonation', { cartId });
	}

	getCreditCards() {
		return this.getRequest('GetCreditCards');
	}

	getCreditCardsLastDigits(subscriptionId) {
		return this.getRequest('GetSubscriptionCreditCard', { subscriptionId });
	}

	changeSignature(changeRequest) {
		return this.postRequest('ChangeSignature', changeRequest);
	}

	inactivateCreditCard(cardId) { 
		return this.putRequest('InactivateCreditCard', { cardId });
	}

	createCreditCard(cc) {
		return this.postRequest('CreateCreditCard', cc);
	}

	createCreditCardOnGateway(creditCard) {
		return this.postRequest('CreateCreditCardOnGateway', creditCard);
	}

	deleteCreditCard(creditCardId){
		return this.postRequest('DeleteCreditCard', { creditCardId} )
	}

	getConsentTermByCartId(cartId) {
		return this.getRequest('GetConsentTermByCartId', { cartId });
	}

	getDolarPrice() {
		return this.getRequest('GetDolarPrice');
	}

	getQrCodeActiveByCart(cartId){
		return this.getRequest('GetQrCodeActiveByCart', {cartId});
	}

	getAllTicketPDF(cart){
		return this.postRequest('GetAllTicketPDF', cart);
	}

	changeTicketDueDate(cartId, newDayOfMonth){
		return this.postRequest('ChangeTicketDueDate', { cartId, newDayOfMonth });
	}

	loadPaymentMethodsWithCompany() {
		return this.postRequest('LoadPaymentMethodsWithCompany')
	}
}